<template>
  <main class="main">
    <section class="left">
      <div class="login_outer">
        <div class="login_inner">
          <div>
            <h1 class="text-2xl font-bold">管理平台</h1>
          </div>

          <vs-alert v-if="login_errorMsg" color="danger" gradient>
            <template #title>發生錯誤，請稍候再試 😵</template>
            {{ login_errorMsg }}
          </vs-alert>

          <div>
            <ValidationObserver class="space-y-6" v-slot="{ invalid }">
              <div class="relative">
                <ValidationProvider name="帳號" rules="required" v-slot="{ errors }">
                  <vs-input type="account" v-model="account" placeholder="帳號">
                    <template #icon>
                      <svg-icon icon-class="account" style="fill: black"></svg-icon>
                    </template>
                  </vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>

              <div class="relative">
                <ValidationProvider mode="aggressive" name="密碼" rules="required" v-slot="{ errors }">
                  <vs-input
                    @keydown.enter="login(invalid)"
                    type="password"
                    v-model="password"
                    placeholder="密碼"
                    :visiblePassword="hasVisiblePassword"
                    @click-icon="hasVisiblePassword = !hasVisiblePassword"
                  >
                    <template #icon>
                      <svg-icon v-if="password === ''" icon-class="password" style="fill: black"></svg-icon>
                      <svg-icon v-else-if="hasVisiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                      <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
                    </template>
                  </vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>

              <div class="text-sm flex items-start justify-between">
                <div class="space-x-5 text-gray-text_light">
                </div>
                <vs-button
                  :disabled="invalid"
                  class="font-medium"
                  ref="login"
                  size="xl"
                  style="border-radius: 12px"
                  color="rgb(125, 138, 249)"
                  @click="login(invalid)"
                  >登入</vs-button
                >
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>

    <section class="right"></section>

  </main>
</template>

<script>
export default {
  data() {
    return {
      account: '', // 登入帳號
      password: '', // 登入密碼
      hasVisiblePassword: false, // 是否開啟密碼預覽
      login_errorMsg: '', // 登入錯誤訊息
    }
  },
  computed: {
    _userDevice() {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android終端
      let isiOS = /(iPhone|iPad|iPod|iOS)/.test(u)
      return isAndroid ? 1 : isiOS ? 2 : 0
    },
  },
  methods: {
    login(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.login,
        scale: '0.6',
        background: 'rgb(125, 138, 249)',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'backstage/accounts/login',
        method: 'post',
        params: {
          device: this._userDevice,
          account: this.account,
          password: this.password,
        },
      })
        .then((res) => {
          if (res.data.status) {
            // 關閉按鈕loading
            loading.close()
            // 儲存token
            this.$store.commit('login/UPDATE_COOKIE', res.data.data)
            // 若有附帶導航網址
            if (this.$route.query.redirect) {
              this.$router.replace({
                path: this.$route.query.redirect,
              })
            } else {
              this.$router.replace({
                name: 'admin',
              })
            }
          } else {
            this.$myVS.openNoti('發生錯誤', res.data.message, 'danger', 3000)
            loading.close()
          }
        })
    },
    dialog_toggle(value) {
      let query = this.$route.query
      if (value) {
        // 新增參數
        this.$router.replace({
          query: { ...query },
        })
      } else {
        // 刪除參數需要用到深拷貝
        // 將物件轉成字串再轉成物件，這樣就真的可以確保出來的會是一個新的物件而且是使用不同的記憶體。
        let newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 深拷貝
        this.$router.replace({ query: newQuery }) // 再覆蓋
      }
    },
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: underline;
}

.main {
  @apply flex flex-col lg:flex-row h-screen text-gray-text_dark;
}

.left {
  @apply h-full relative flex-shrink-0 flex-grow
  flex items-center justify-center;

  background: url('../assets/images/login/bg2.png') center/cover;

  @screen lg {
    min-width: 450px;
    background: white;
  }
}

.login_outer {
  @apply bg-white
    rounded-2xl lg:absolute 
    p-6 shadow-2xl
    flex items-center justify-center
    lg:top-1/2 lg:-right-1/3 lg:transform lg:-translate-y-1/2
    w-full h-auto m-3 sm:m-0;

  background: rgba(255, 255, 255, 1);
  // backdrop-filter: blur(5px);

  @screen sm {
    width: 400px;
    height: 500px;
  }

  .login_inner {
    @apply w-full space-y-6;
  }
}

.right {
  @apply h-full w-full lg:w-16/20 hidden lg:block;
  background: url('../assets/images/login/bg2.png') center/cover;
}

::v-deep .vs-input {
  border: none;
  background: rgba(var(--vs-gray-2), 1);
}
</style>
